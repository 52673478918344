import React from "react";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { useDeviceSize } from "react-device-sizes";

function Subscribe() {
  const [visible, setVisible] = React.useState(true);
  const { xsDown } = useDeviceSize();

  return (
    <div
      className={`px-[1%] lg:px-[10%] xl:px-[15%] py-2 bg-[#ffa264] ${
        visible ? "flex items-center justify-between" : "hidden"
      }`}
    >
      {/* <button
        onClick={() => setVisible(false)}
        className="text-black bg-transparent border border-black text-xs md:text-sm py-0 px-1 rounded-full"
      >
        X
      </button> */}
      <IoMdCloseCircleOutline
        onClick={() => setVisible(false)}
        className="text-black text-xl cursor-pointer"
      />
      <div className="text-center w-[65%] sm:w-auto">
        <p className="text-black sm:font-bold text-[.56rem] md:text-base">
          OFFRES SECTORIELLES - <br className="md:hidden" /> Responsabilité
          Civile et Garantie Financière
        </p>
        <p className="text-black text-[.56rem] md:text-sm">
          {xsDown
            ? "Profitez d'offres dediees aux intermediaires d'assurance, de l'immobilier"
            : "Profitez d'offres dédiées aux intermédiaires d'assurance, de l'immobilier et du secteur financier."}
        </p>
      </div>
      {/* <Button
        link="https://extranet.oceanbridges.fr/"
        text="SUBSCRIBE"
        color="transparent"
        textColor="black"
        borderColor="black"
        size="xs"
        sizeSm="[.6rem]"
        radius="lg"
        padding={4}
        hoverColor="black"
      /> */}
      <a
        href="https://extranet.oceanbridges.fr/"
        className="text-[.5rem] sm:text-sm bg-transparent text-black border-black font-semibold transition duration-300 hover:text-white hover:bg-black rounded-md border py-1 px-4 md:px-4"
      >
        {xsDown ? "DECOUVRIR" : "SOUSCRIRE"}
      </a>
    </div>
  );
}

export default Subscribe;
