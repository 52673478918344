import "./App.css";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Layout from "./pages/Layout";
import NoPage from "./pages/NoPage";
import CustomOffers from "./pages/CustomOffers";
import RealEstateFinancialIntermediary from "./pages/RealEstateFinancialIntermediary";
import RealEstateProfessions from "./pages/RealEstateProfessions";
import Files from "./pages/Files";
import ScrollToTop from "./components/ScrollToTop";
import SubscribePage from "./pages/SubscribePage";
import Claim from "./pages/Claim";
import Faq from "./pages/Faq";
import Regulation from "./pages/Regulation";
import OBConnect from "./pages/OBConnect";
import Recrutement from "./pages/Recrutement";
import Sinister from "./pages/Sinister";
import News from "./pages/News";
import Responsability from "./pages/Responsability";
import Calendar from "./pages/Calendar";
import Conditions from "./pages/Conditions";
import Legal from "./pages/Legal";
import Politic from "./pages/Politic";
import CookiesScreen from "./pages/Cookies";
import SpringModal from "./components/common/SpringModal";
import { useEffect, useState } from "react";
import CookiesModal from "./components/common/CookiesModal";
import { ContactModalProvider } from "./hooks/useContactModal";

//todo; will remove in production version ************************************************************

import CookieConsent, { Cookies, getCookieConsentValue } from "react-cookie-consent";
import {FormProvider} from "./context/ReclamaionFormContext";
import Identification from "./pages/Claim/Identification";
import ContactInfo from "./pages/Claim/ContactInfo";
import FirstConfirmation from "./pages/Claim/FirstConfirmation";
import Confirmation from "./pages/Claim/Confirmation";

function App() {
  const [isCookiesOpen, setCookiesOpen] = useState<boolean>(false);

  useEffect(() => {
    let cookie = getCookieConsentValue("userConsent");
    cookie != null ? setCookiesOpen(false) : setCookiesOpen(true);
  }, []);

  const openCookies = () => {
    setCookiesOpen(true);
  };

  const closeCookies = () => {
    setCookiesOpen(false);
  };

  const toggleCookiesOpen = () => {
    setCookiesOpen(!isCookiesOpen);
  };

  return (
    <div className="App font-montserrat zoom-80">
      <header className="">
        <ContactModalProvider>
          <Router>
            {/* React Cookie Consent Component */}
            {/* <CookieConsent
              location="bottom"
              buttonText="Tout accepter"
              declineButtonText="Tout refuser"
              enableDeclineButton
              onAccept={() => {
                // Handle accept action here
                // Cookies.set("analytics", "true", { expires: 180 });
              }}
              onDecline={() => {
                // Handle decline action here
                Cookies.set("analytics", "false", { expires: 180 });
              }}
              cookieName="userConsent"
            >
              Ocean Bridges utilise des cookies pour assurer le bon fonctionnement du site,
              personnaliser la publicité, et analyser le trafic.
              <button onClick={openCookies} style={{ marginLeft: "10px", color: "blue", textDecoration: "underline", border: "none", background: "transparent" }}>
                Gérer mes cookies
              </button>
            </CookieConsent> */}

            {/* Existing Modal for detailed cookie settings */}
            {isCookiesOpen && (
              <SpringModal
                widthClass="w-[100%] md:max-w-[80%] 2xl:max-w-[50%] h-auto"
                isOpen={isCookiesOpen}
                toggleIsOpen={closeCookies}
                children={<CookiesModal closeCookiesModal={closeCookies} />}
              />
            )}

            <ScrollToTop />
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
                <Route path="/custom-offers" element={<CustomOffers />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/files" element={<Files />} />
                <Route path="https://extranet.oceanbridges.fr/" element={<SubscribePage />} />
                <Route path="/claim" element={<Claim />} />
                <Route
                    path="/claim/yours"
                    element={
                      <FormProvider>
                        <Identification />
                      </FormProvider>
                    }
                />
                <Route
                    path="/claim/contact"
                    element={
                      <FormProvider>
                        <ContactInfo />
                      </FormProvider>
                    }
                />
                <Route
                    path="/claim/first-confirmation"
                    element={
                      <FormProvider>
                        <FirstConfirmation />
                      </FormProvider>
                    }
                />
                <Route path="/claim/confirmation" element={<Confirmation />} />
                <Route path="/faq" element={<Faq />} />
                <Route path="/regulation" element={<Regulation />} />
                <Route path="/ob-connect" element={<OBConnect />} />
                <Route path="/recruitment" element={<Recrutement />} />
                <Route path="/sinister" element={<Sinister />} />
                <Route path="/news" element={<News />} />
                <Route path="/responsability" element={<Responsability />} />
                <Route path="/calendar" element={<Calendar />} />
                <Route path="/conditions" element={<Conditions />} />
                <Route path="/legal" element={<Legal />} />
                <Route path="/politic" element={<Politic />} />
                <Route
                  path="/cookies"
                  element={<CookiesScreen setOpenCookiesModal={openCookies} />}
                />
                <Route
                  path="/real-estate-financial-intermediary"
                  element={<RealEstateProfessions />}
                />
                <Route
                  path="/real-estate-professions"
                  element={<RealEstateFinancialIntermediary />}
                />
                <Route path="*" element={<NoPage />} />
              </Route>
            </Routes>
          </Router>
        </ContactModalProvider>
      </header>

    </div>
  );
}

export default App;
