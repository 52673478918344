import { BrowserView, MobileView } from "react-device-detect";
import Button from "../Button";

function Login() {
  return (
    <div className="bg-[#e5e1d3] text-left p-8 h-[100%]">
      <BrowserView>
        <h1 className="font-bold text-lg">Connectez-vous</h1>
        <h2 className="text-lg mb-8">Pour accéder à votre espace partenaire</h2>
        <div className="my-4">
          <label className="block text-onAccent text-xl">Email</label>
          <input
            type="email"
            className="py-2 px-4 w-full focus:border-l-8 border-orange-400"
          />
        </div>
        <div className="my-4">
          <label className="block text-onAccent text-xl">Mot de passe</label>
          <input
            type="password"
            className="py-2 px-4 w-full focus:border-l-8 border-orange-400"
          />
        </div>
        <div className="grid xl:grid-cols-2 gap-6 items-center mt-6 w-[100%] xl:w-[100%]">
          <button className="bg-black text-white font-semibold px-4 2xl:px-8 py-2 uppercase text-base md:text-sm 2xl:text-lg">
            Se connecter
          </button>
          <a href="https://extranet.oceanbridges.fr/" className="text-onAccent text-xs 2xl:text-lg">
            Mot de passe oublié ?
          </a>
        </div>
      </BrowserView>
      <MobileView>
        <h1 className="font-bold text-sm">Connectez-vous</h1>
        <h2 className="text-xs ">Pour accéder à votre espace partenaire</h2>
        <h3 className="text-primary text-sm font-bold my-6">
          - Accès non disponible sur mobile -
        </h3>
        <p className="text-sm ">
          Nous regrettons de vous informer que cette fonctionnalité n'est pas disponible sur mobile.
          Pour une expérience optimale, veuillez accéder à cette section depuis un ordinateur de bureau.

        </p>
        <p className="text-sm mt-8">Assuré de votre compréhension.</p>
        <div className="flex justify-center mt-14">
          <Button
            sizeSm="[.7rem]"
            text="RDV SUR VOTRE ORDINATEUR"
            color="primary"
            textColor="white"
            radius="full"
            // sizeSm="[.6rem]"
            width="100"
          />
        </div>
      </MobileView>
    </div>
  );
}

export default Login;
