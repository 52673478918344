import React from "react";
import logo from "../../assets/logo.svg";
import settings from "../../assets/icons/settings.svg";
import clock from "../../assets/icons/clock.svg";
import share from "../../assets/icons/share.svg";
import Button from "../Button";
import CookiesSettings from "./CookiesSettings";
import { Cookies } from "react-cookie-consent";

interface CookiesModalProps {
  closeCookiesModal: () => void;
}

const CookiesModal: React.FC<CookiesModalProps> = ({ closeCookiesModal }) => {
  const [isPersonalizedOpen, setPersonalizedOpen] =
    React.useState<boolean>(false);

  const cookies = [
    {
      icon: settings,
      title: "Utilisation des cookies",
      text: "Notre site, oceanbridges.fr, utilise des cookies pour garantir son bon fonctionnement...",
    },
    {
      icon: clock,
      title: "Vos choix en matière de cookies",
      text: "Lorsque vous utilisez cet outil, un cookie sera placé sur votre appareil pour mémoriser vos préférences...",
    },
    {
      icon: share,
      title: "Liste des cookies utilisés",
      text: "",
    },
    {
      // No icon for this cookie
      title: "Cookies analytiques",
      text: "Nous utilisons des cookies Google Analytics pour collecter des informations anonymes...",
    },
    {
      // No icon for this cookie
      title: "Gérer vos préférences",
      text: "Pour ajuster vos préférences concernant l'utilisation des cookies...",
    },
  ];

  return (
    <div className="items-center">
      <div className="flex justify-center p-4 md:px-12 md:pt-8">
        <img src={logo} alt="Logo" className="h-10 2xl:h-14" />
      </div>
      {isPersonalizedOpen ? (
        <CookiesSettings close={closeCookiesModal} />
      ) : (
        <div className="flex flex-col gap-4 p-0 md:px-12 md:pb-8">
          <h1 className="text-center sm:text-left text-primary font-bold text-lg lg:text-xl 2xl:text-2xl mt-4 2xl:mt-8 mb-6">
            Gérer mes cookies
          </h1>
          {cookies.map((cookie, index) => (
            <div
              className="flex flex-col sm:flex-row gap-4 text-center sm:text-left"
              key={index}
            >
              {cookie.icon ? (
                <img
                  src={cookie.icon}
                  alt=""
                  className="mb-auto h-6 2xl:h-8 mx-auto sm:mx-0"
                  loading="lazy"
                />
              ) : (
                <span className="text-xl font-bold">*</span> // Display asterisk instead of the button
              )}
              <div className="w-[80%] md:w-[100%] self-center">
                <h1 className="text-base lg:text-lg 2xl:text-xl font-bold">
                  {cookie.title}
                </h1>
                <p className="text-xs 2xl:text-sm text-center md:text-left xl:text-left w-[100%]">
                  {cookie.text}
                </p>
              </div>
            </div>
          ))}
          <div className="w-[80%] md:w-[100%] self-center">
            <a
              href="/cookies"
              className="text-center md:text-left text-sm 2xl:text-lg mt-3 2xl:mt-6 mb-6 2xl:mb-12 underline lg:float-left block"
            >
              Consulter la page « utilisation des cookies »
            </a>
          </div>

          <div className="grid lg:grid-cols-3 w-[80%] lg:w-[80%] self-center lg:mx-auto gap-4">
            <button
              onClick={() => {
                setPersonalizedOpen(true);
              }}
              className="bg-transparent hover:bg-blue text-black text-xs font-semibold md:text-xs transition duration-300 hover:text-white hover:bg-black py-2 px-4 mx-4 border border-black hover:border-transparent rounded-full"
            >
              CHOIX PERSONNALISES
            </button>

              <Button
                onClick={() => {
                  closeCookiesModal();
                  Cookies.set("userConsent", "declined", { expires: 180 });
                }}
                text="TOUT REFUSER"
                color="black"
                textColor="white"
                size="sm"
                sizeSm="[.6rem]"
                width="100"
            />

            {/*<Button*/}
            {/*    link="https://extranet.oceanbridges.fr/"*/}
            {/*    text={assurances[0].button_text}*/}
            {/*    color="black"*/}
            {/*    textColor="white"*/}
            {/*    size="md"*/}
            {/*    sizeSm="[.6rem]"*/}
            {/*    width="100"*/}
            {/*/>*/}
            <Button
              onClick={() => {
                closeCookiesModal();
                Cookies.set("userConsent", "accepted", { expires: 180 });
              }}
              text="TOUT ACCEPTER"
              color="primary"
              textColor="white"
              size="sm"
              sizeSm="[.6rem]"
              width="100"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CookiesModal;
