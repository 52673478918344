import React from "react";
import { BoxReveal } from "../box-reveal";
import { useDeviceSize } from "react-device-sizes";

// Define props interface for HeroTitle
interface HeroTitleProps {
  title: string;
  subTitle: string;
}

function HeroTitle({ title, subTitle }: HeroTitleProps) {
  const { xsDown } = useDeviceSize();

  return (
    <div className="h-full w-[70vw] md:w-full max-w-[55%] md:max-w-[40%] lg:max-w-[40%] xl:max-w-[35%] 2xl:max-w-[23%] items-center justify-center overflow-hidden absolute inset-x-[2%] inset-y-[5%] md:inset-x-[15%] md:inset-y-[10%] 2xl:inset-x-[20%] 2xl:inset-y-[10%] z-10 pointer-events-none">
      <BoxReveal boxColor={""} duration={0.5}>
        <h1 className=" text-left text-white text-base md:text-xl 2xl:text-3xl">
          {xsDown ? (
            <>
              Intermédiaire <br />
              d'assurance & financier
            </>
          ) : (
            title || "Intermédiaire d'assurance, immobilier et financier"
          )}
        </h1>
      </BoxReveal>

      <BoxReveal boxColor={""} duration={0.5}>
        <div className="text-white font-bold mt-[.5rem] sm:mt-[1.5rem] text-left text-base md:text-2xl 2xl:text-4xl">
          <h2>
            {subTitle || (
              <>
                Découvrez Notre Offre. <br className="hidden sm:block" />
                Responsabilité civile et Garantie financiere. <br />
              </>
            )}
          </h2>
        </div>
      </BoxReveal>

      <div className="m-6"></div>

      <BoxReveal boxColor={""} duration={0.5}>
        <a href="https://extranet.oceanbridges.fr/">
          <button className="bg-transparent hover:bg-blue text-white  text-sm md:font-semibold md:text-base transition duration-300 hover:text-black hover:bg-white py-2 px-4 border border-blue hover:border-transparent rounded-full pointer-events-auto">
            OBTENIR UN DEVIS
          </button>
        </a>
      </BoxReveal>
    </div>
  );
}

export default HeroTitle;

// import React from "react";
// import { BoxReveal } from "../box-reveal";
// import { useDeviceSize } from "react-device-sizes";

// function HeroTitle() {
//   const { xsDown } = useDeviceSize();

//   return (
//     <div className="h-full w-[70vw] md:w-full max-w-[55%] md:max-w-[40%] lg:max-w-[40%] xl:max-w-[35%] 2xl:max-w-[23%] items-center justify-center overflow-hidden absolute inset-x-[2%] inset-y-[5%] md:inset-x-[15%] md:inset-y-[10%] 2xl:inset-x-[20%] 2xl:inset-y-[10%] z-10 pointer-events-none">
//       <BoxReveal boxColor={""} duration={0.5}>
//         <h1 className=" text-left text-white text-base md:text-xl 2xl:text-3xl">
//           {xsDown ? (
//             <>
//               Intermédiaire <br />
//               d'assurance & financier
//             </>
//           ) : (
//             "Intermédiaire d'assurance, immobilier et financier"
//           )}
//         </h1>
//       </BoxReveal>

//       <BoxReveal boxColor={""} duration={0.5}>
//         <div className="text-white font-bold mt-[.5rem] sm:mt-[1.5rem] text-left text-base md:text-2xl 2xl:text-4xl">
//           <h2>
//             Découvrez Notre Offre. <br className="hidden sm:block" />
//             Responsabilité civile et Garantie financiere. <br />
//           </h2>
//         </div>
//       </BoxReveal>

//       <div className="m-6"></div>

//       <BoxReveal boxColor={""} duration={0.5}>
//         <a href="https://extranet.oceanbridges.fr/">
//           <button className="bg-transparent hover:bg-blue text-white  text-sm md:font-semibold md:text-base transition duration-300 hover:text-black hover:bg-white py-2 px-4 border border-blue hover:border-transparent rounded-full pointer-events-auto">
//             OBTENIR UN DEVIS
//           </button>
//         </a>
//       </BoxReveal>
//     </div>
//   );
// }

// export default HeroTitle;
